<template>
  <el-checkbox-group v-model="val">
    <el-checkbox v-for="(item,key) in state" :key="key" :label="item.value">{{item.label}}</el-checkbox>
  </el-checkbox-group>
</template>

<script>
import state from "@/components/roleAccess/state";

export default {
  name: "roleAccess",
  model:{
    prop:"modelValue",
    event:"change"
  },
  props:{
    modelValue:{
      type:String,
      default:"",
    }
  },
  watch:{
    modelValue:{
      handler:"modelValueChange",
      immediate:true,
      deep:true
    },
    val(e){
      this.$emit('change',e.join(","));
    }
  },
  methods:{
    modelValueChange(){
      if (this.modelValue !== "")this.val = this.modelValue.split(",");
    }
  },
  data(){
    return{
      val:[],
      state:state
    }
  }
}
</script>

<style scoped>

</style>